





















import { Vue, Component, Prop } from "vue-property-decorator";
import { MainProperties } from "./MainView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogProperties";

const PendingOrderBView = () => import("./PendingOrderBView.vue");
const PendingSummaryView = () => import("./PendingSummaryView.vue");

class PendingOrderProperties {
    pendingOrders: any[] = [];
    currency: any = null;
}

export { PendingOrderProperties };

@Component({
    components: {
        PendingOrderBView,
        PendingSummaryView
    }
})
export default class PendingOrderAView extends Vue {
    @Prop() private main: MainProperties;
    @Prop() private alertDialog: AlertDialogProperties;

    private properties = new PendingOrderProperties();


}
